import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import NewsItem from "../components/NewsItem"
import SEO from "../components/seo"

export default function Template({ data }) {
  const item = data.markdownRemark // data.markdownRemark holds your news item
  const {
    frontmatter: { title, description, headline },
  } = item
  const meta = description || headline
  return (
    <Layout backgroundColor={"white"} navColor={"black"} isArchive>
      <SEO
        title={`Thesis | ${title}`}
        keywords={["thesis agency", "portland agency", "marketing agency"]}
        description={meta}
      />
      <NewsItem item={item} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        eyebrow
        headline
        heroImage
        summary
      }
    }
  }
`
