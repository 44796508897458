import React from "react"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"

import { fit } from "util/imgResize"

import styles from "./styles.module.less"

const NewsItem = ({ item, preview = false }) => {
  const {
    html,
    frontmatter: { eyebrow, headline, heroImage, previewImage },
  } = item

  const renderHeader = (headline, eyebrow, heroImage) => {
    if (heroImage) {
      return (
        <HeroHeader
          headline={headline}
          eyebrow={eyebrow}
          heroImage={heroImage}
        />
      )
    } else {
      return <TextHeader headline={headline} eyebrow={eyebrow} />
    }
  }

  return (
    <section className={styles.NewsItem}>
      <Reveal>{renderHeader(headline, eyebrow, heroImage)}</Reveal>
      <Reveal>
        <article className={styles.article}>
          <Body html={html} />
        </article>
      </Reveal>
      {/* {!preview && (
        <Reveal>
          <Link to="/news/" className={styles.NewsCta}>
            Back to news
          </Link>
        </Reveal>
      )} */}
    </section>
  )
}

export default NewsItem

const HeroHeader = ({ headline, eyebrow, heroImage }) => {
  const headStyle = {
    backgroundImage: `url(${fit(heroImage, 1440)})`,
  }
  return (
    <header className={styles.heroHeader} style={headStyle}>
      <h1>
        <span className={styles.eyebrow}>{eyebrow}</span>
        {headline}
      </h1>
    </header>
  )
}

const TextHeader = ({ headline, eyebrow }) => {
  return (
    <header className={styles.newsHeader}>
      <span className={styles.eyebrow}>{eyebrow}</span>
      <h1>{headline}</h1>
    </header>
  )
}

const Body = ({ html }) => {
  if (typeof html === "string") {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={styles.markdown}
      />
    )
  } else {
    return <div className={styles.markdown}>{html}</div>
  }
}
